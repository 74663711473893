import { useState } from "react";
import Modal from "../Base/Modal";
import { Keypair } from "@solana/web3.js";
import bs58 from 'bs58'

export default function ZombieDialog({ isOpen, onOK, onCancel }) {
  const [privateKey, setPrivateKey] = useState("");
  const [type, setType] = useState(false);
  const [iSavedPK, setISavedPK] = useState(false);

  const handleOK = () => {
    if (privateKey !== "") {
      setPrivateKey("");
      setType(false);
      setISavedPK(false);
      onOK(privateKey);
    }
  };

  const handleCancel = () => {
    setPrivateKey("");
    setType(false);
    setISavedPK(false);
    onCancel();
  };

  const handleGenerate = () => {
    const keypair = Keypair.generate();
    const secretKeyString = bs58.encode(keypair.secretKey);
    setPrivateKey(secretKeyString);
  };

  return (
    <Modal isOpen={isOpen} onClose={handleCancel}>
      <div className="flex flex-col pt-5 w-[440px] font-sans">
        <div className="flex items-center justify-start w-full h-auto px-5 py-3 rounded-t-md bg-gray-highlight">
          <div className="font-sans text-sm font-medium text-white uppercase">
            Set Zombie Wallet
          </div>
        </div>

        <div className="flex flex-col bg-gray-dark px-5 pt-5 gap-2">
          <div className="">
            <div className="flex font-sans text-md uppercase text-gray-normal gap-1">
              <input
                type="checkbox"
                checked={type}
                onChange={() => setType(!type)}
              />
              <p>Import from private key</p>
            </div>
            {type === true && (
              <input
                type="password"
                className="outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent w-full h-button mt-1 rounded-md"
                placeholder="Enter Private Key"
                value={privateKey}
                onChange={(e) => setPrivateKey(e.target.value)}
              />
            )}
          </div>
          <div className="flex justify-between items-center">
            <div className="flex font-sans text-md uppercase text-gray-normal gap-1">
              <input
                type="checkbox"
                checked={!type}
                onChange={() => {
                  setType(!type);
                  setPrivateKey("");
                  setISavedPK(false);
                }}
              />
              <p>Generate new wallet</p>
            </div>
            {type === false && (
              <button
                className="pl-3 pr-4 h-button rounded-[4px] justify-center items-center gap-1 inline-flex bg-[#262626] active:scale-95 transition duration-90 ease-in-out transform focus:outline-none text-xs font-medium text-center text-white uppercase disabled:text-gray-border disabled:opacity-50 disabled:cursor-not-allowed whitespace-nowrap"
                onClick={handleGenerate}
              >
                Generate
              </button>
            )}
          </div>
          {type === false && (
            <div className="flex flex-col gap-2 px-4">
              <input
                type="text"
                className="outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent w-full h-button mt-1 rounded-md"
                placeholder="Private Key goes here"
                value={privateKey}
                onChange={(e) => {}}
              />
              <div className="flex font-sans text-md uppercase text-gray-normal gap-1">
                <input
                  type="checkbox"
                  checked={iSavedPK}
                  onChange={() => setISavedPK(!iSavedPK)}
                />
                <p>I've saved private key</p>
              </div>
            </div>
          )}
        </div>

        <div className="items-center w-full h-auto px-5 py-5 md:py-0 bg-gray-dark rounded-b-md">
          <div className="flex items-center justify-center gap-5 my-5">
            <button
              className="pl-3 pr-4 h-button grow rounded-[4px] justify-center items-center gap-1 inline-flex bg-purple-normal active:scale-95 transition duration-90 ease-in-out transform focus:outline-none text-xs font-medium text-center text-white uppercase disabled:text-gray-border disabled:opacity-50 disabled:cursor-not-allowed whitespace-nowrap"
              disabled={
                privateKey === "" || (type === false && iSavedPK === false)
              }
              onClick={handleOK}
            >
              OK
            </button>
            <button
              className="pl-3 pr-4 h-button grow rounded-[4px] justify-center items-center gap-1 inline-flex bg-[#262626] active:scale-95 transition duration-90 ease-in-out transform focus:outline-none text-xs font-medium text-center text-white uppercase disabled:text-gray-border disabled:opacity-50 disabled:cursor-not-allowed whitespace-nowrap"
              onClick={handleCancel}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
