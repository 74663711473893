import { useMemo, useState } from "react";
import Modal from "../Base/Modal";
import { toast } from "react-toastify";

export default function SolRangeAmountDialog({ isOpen, onOK, onCancel, selected }) {
  const [minAmount, setMinAmount] = useState("");
  const [maxAmount, setMaxAmount] = useState("");

  const handleOK = () => {
    if (minAmount === "" || Number(minAmount) <= 0) {
      toast.warn("Invalid minimum SOL amount!");
      return;
    }
    if (maxAmount === "" || Number(maxAmount) < Number(minAmount)) {
      toast.warn("Invalid maximum SOL amount!");
      return;
    }
    if (minAmount !== "" && maxAmount !== "") {
      onOK(minAmount, maxAmount);
    }
  };

  const handleCancel = () => {
    setMinAmount("");
    setMaxAmount("");
    onCancel();
  };

  const desc = useMemo( () => {
    if (minAmount !== "" && maxAmount !== "") {
      if (Number(maxAmount) < Number(minAmount)) {
        return 'Please input correct min, max amount'
      } else if (Number(maxAmount) == Number(minAmount)) {
        return `Zombie wallet should have more than ${maxAmount * selected} SOL.`  
      }
      return `Zombie wallet should have more than ${maxAmount * selected} SOL.`
    }
    else if (minAmount !== "") {
      return `At least ${minAmount * selected} SOL required`
    }
    else if (maxAmount !== "") {
      return `At most ${maxAmount * selected} SOL required`
    }
    return '';
  },[minAmount, maxAmount])

  return (
    <Modal isOpen={isOpen} onClose={handleCancel}>
      <div className="flex flex-col pt-5 w-[440px] font-sans">
        <div className="flex items-center justify-start w-full h-auto px-5 py-3 rounded-t-md bg-gray-highlight">
          <div className="font-sans text-sm font-medium text-white uppercase">
            Set Sol Amount
          </div>
        </div>
        <div className="items-center w-full h-auto px-5 py-5 md:py-0 bg-gray-dark rounded-b-md">
          <div className="mt-5">
            <div className="font-sans text-xs uppercase text-gray-normal">
              Min Amount<span className="pl-1 text-purple-normal">*</span>
            </div>
            <input
              className="outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent w-full h-button mt-1"
              placeholder="Enter minimum Sol amount"
              value={minAmount}
              onChange={(e) => setMinAmount(e.target.value)}
            />
          </div>
          <div className="mt-4">
            <div className="font-sans text-xs uppercase text-gray-normal">
              Max Amount<span className="pl-1 text-purple-normal">*</span>
            </div>
            <input
              className="outline-none border border-gray-border font-sans text-white placeholder:text-gray-border text-sm px-2.5 bg-transparent w-full h-button mt-1"
              placeholder="Enter maximum Sol amount"
              value={maxAmount}
              onChange={(e) => setMaxAmount(e.target.value)}
            />
          </div>
          {desc && <div className="mt-4">
            <div className="font-sans text-xs text-gray-normal">
              <span className="pl-1 text-red-normal">{desc}</span>
            </div>
          </div>}
          <div className="flex items-center justify-center gap-5 my-5">
            <button
              className="pl-3 pr-4 h-button grow rounded-[4px] justify-center items-center gap-1 inline-flex bg-purple-normal active:scale-95 transition duration-90 ease-in-out transform focus:outline-none text-xs font-medium text-center text-white uppercase disabled:text-gray-border disabled:opacity-50 disabled:cursor-not-allowed whitespace-nowrap"
              onClick={handleOK}
            >
              OK
            </button>
            <button
              className="pl-3 pr-4 h-button grow rounded-[4px] justify-center items-center gap-1 inline-flex bg-[#262626] active:scale-95 transition duration-90 ease-in-out transform focus:outline-none text-xs font-medium text-center text-white uppercase disabled:text-gray-border disabled:opacity-50 disabled:cursor-not-allowed whitespace-nowrap"
              onClick={handleCancel}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
